import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { VersionService } from './services/version/version.service';
import { SessionStorageService } from './services/session-storage/session-storage.service';
import { SessionStorageKey } from './services/session-storage/session-storage.constants';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = `Loading:  Angel's Sword RPG`;

  constructor(private version: VersionService, private sessionStorage: SessionStorageService) {
    if (!this.sessionStorage.get(SessionStorageKey.SessionId)) {
      this.sessionStorage.set(SessionStorageKey.SessionId, btoa(uuidv4()));
    }
  }

  ngOnInit(): void {
    this.version.init();
  }
}
